<template lang="pug">
.brand-wrapper-fluid.container-fluid(:class="$route.name")
  .row
    .d-flex.col-4.col-lg-2.px-0.setting-tab
      side-sub-nav(:menuItems="tabs")
    .col-8.col-lg-10.py-5.px-4.setting-tab-page
      router-view
</template>

<script>
  import { mapState, mapGetters, createNamespacedHelpers } from 'vuex';
  import SideSubNav from '@/components/SideSubNav.vue';
  import GET_PUBLIC_FEATURES from '@/graphql/GetPublicFeatures.gql';
  import { isSpamProtectionMenuEnabled } from '@/utils/features';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    components: {
      SideSubNav,
    },
    data() {
      return {
        currentTab: 0,
      };
    },
    computed: {
      ...mapState(['impersonate', 'accountType', 'account']),
      ...mapGetters(['isAffiliate', 'isManagedReferral', 'isOwner', 'accountFeatures']),
      ...paymentGetters(['isPaymentEnabledForUser']),
      features() {
        return this.account && this.account.features ? this.account.features : [];
      },
      tabs() {
        const tabs = [
          { name: this.$t('personalDetails'), link: 'account_settings' },
          { name: this.$t('changeEmail.label'), link: 'change_email' },
          { name: this.$t('changePassword'), link: 'change_password' },
        ];
        if (this.isPaymentEnabledForUser) {
          tabs.push(
            ...[
              { name: this.$t('billingAddress'), link: 'billing_address' },
              { name: this.$t('paymentMethod'), link: 'payment_method' },
              { name: this.$t('invoiceHistory'), link: 'invoice_history' },
            ],
          );
        }

        if (
          (this.accountType === 'normal' || this.accountType === 'sub') &&
          !this.features.includes('EXPERIMENT_GLOBAL_FREQUENCY_CAP')
        ) {
          tabs.push({
            name: this.$t('globalFrequencyCap.heading'),
            link: 'user_experience_protector_settings',
          });
        }

        if (this.accountType !== 'agency') {
          tabs.push({ name: this.$t('domains'), link: 'domains' });
        }

        tabs.push({ name: this.$t('emailNoti.title'), link: 'email_notifications' });

        if (this.accountType === 'agency' && !this.isManagedReferral && this.isOwner) {
          tabs.push({
            name: this.$t('whiteLabelSettings'),
            link: 'white_label_settings',
          });
        }

        if ((!this.impersonate && this.accountType === 'normal') || this.accountType === 'agency') {
          tabs.push({
            name: this.$t('users'),
            link: 'account_sharing',
          });
        }

        if (this.accountType !== 'agency') {
          tabs.push({ name: this.$t('insertCode'), link: 'settings-code-insert' });
        }

        if (isSpamProtectionMenuEnabled(this.features)) {
          tabs.push({ name: this.$t('spamProtection'), link: 'spam_protection', classes: 'beta' });
        }

        if (this.accountType !== 'agency') {
          tabs.push({ name: this.$t('conversionGoals.title'), link: 'conversion_goals' });
          tabs.push({ name: this.$t('apiKeySettings.label'), link: 'apikey_settings' });
          tabs.push({ name: this.$t('experimental.menu'), link: 'experimental' });
        }

        if (
          this.accountType !== 'agency' &&
          this.publicFeatures &&
          this.publicFeatures.length > 0
        ) {
          tabs.push({ name: this.$t('optInFeatures.label'), link: 'features' });
        }
        return tabs;
      },
    },
    apollo: {
      publicFeatures: {
        query: GET_PUBLIC_FEATURES,
      },
    },
  };
</script>

<style lang="sass">
  .brand-wrapper-fluid[class*="code-insert"]
    .setting-tab-page
      padding-top: .625rem !important
</style>
